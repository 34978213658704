<template>
    <!-- BaseNotFound.vue -->
    <v-row>
        <v-col cols="12">
            <v-img :height="logoHeight" :src="logo" contain></v-img>
        </v-col>
        <v-col cols="12 text-center">
            <v-card :max-width="cardMaxWidth" class="ma-auto" flat>
                <div :class="`${titleColor}--text font-weight-bold text-h5`">
                    {{ $t('title') }}
                </div>
                <div :class="`text-h6 ${bodyColor}--text my-6`">
                    {{ $t('description') }}
                </div>
                <router-link :to="{path: '/'}" class="error--text">
                    {{ $t('link') }}
                </router-link>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import logo from '@/assets/logo.png'

    /**
     * Base Error View.  Displays a centered logo and the error text.  Also accepts a redirect url.
     */
    export default {
        name: 'BaseNotFound',
        props: {
            /**
             * The max width of the card that contains the text
             */
            cardMaxWidth: {
                type: [Number, String],
                default: 500
            },
            /**
             * Vuetify theme color for the title
             */
            bodyColor: {
                type: String,
                default: 'secondary'
            },
            /**
             * The logo to display above the text
             */
            logo: {
                type: String,
                default: logo
            },
            /**
             * The height of the logo
             */
            logoHeight: {
                type: Number,
                default: 120
            },
            /**
             * Vuetify theme color for the title
             */
            titleColor: {
                type: String,
                default: 'error'
            }
        }
    }
</script>

<i18n>
{
    "en": {
        "title": "Page Not Found (404)",
        "description": "Xiomi and Santi found each other.  But you seem a bit lost.",
        "link": "Take me home"
    },
    "es": {
        "title": "Página no encontrada (404)",
        "description": "Xiomi y Santi se encontraron, pero tu pareces un poco perdido.",
        "link": "Volver al inicio"
    }
}
</i18n>
